var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse }
    },
    [
      _c(
        "transition",
        { attrs: { name: "sidebarLogoFade" } },
        [
          _c(
            "router-link",
            {
              key: "collapse",
              staticClass: "sidebar-logo-link",
              attrs: { to: "/" }
            },
            [
              _c(
                "h1",
                {
                  staticClass: "sidebar-title",
                  staticStyle: {
                    "padding-left": "16px",
                    "font-weight": "bold",
                    "font-size": "24px"
                  }
                },
                [_vm._v("智能管理后台")]
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }