import Cookies from 'js-cookie'

const TokenKey = 'admin-token'

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, { Domain: '.fengtan.com' })
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey, { Domain: '.fengtan.com' })
    return Cookies.remove(TokenKey)

}