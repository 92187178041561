import axios from 'axios'
import {
    Notification,
    MessageBox,
    Message
} from 'element-ui'
import store from '@/store'
import {
    getToken
} from '@/utils/auth'
import errorCode from '@/utils/errorCode'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
    // 创建axios实例
const service = axios.create({
        // axios中请求配置有baseURL选项，表示请求URL公共部分
        baseURL: process.env.VUE_APP_BASE_API,
        // 超时
        timeout: 30000
    })
    // request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    config.headers['Accept'] = '*/*'
    if (config.method == 'post') {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    } else {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    }

    if (getToken() && !isToken) {
        config.headers['token'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode['default']
        if (code === 9001) {
            MessageBox.confirm(`<div class="confirm-notice"><p>登录状态已过期，请重新登录</p><div></div>`, '系统提示', {
                closeOnClickModal: false,
                dangerouslyUseHTMLString: true,
                confirmButtonText: '重新登录',
                // cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('LogOut').then(() => {
                    window.location.href = '/login'
                })
            })
        } else if (code === 500) {
            Message({
                message: msg,
                type: 'error'
            })
            return Promise.reject(new Error(msg))
        } else if (code === 4000) {
            return res.data
        } else if (code !== 200) {
            Message({
                message: res.data.msg,
                type: 'error'
            })
            return Promise.reject(res.data.msg)
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error)
        let {
            message
        } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({
            message: message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service