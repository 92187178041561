import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
console.log(Layout)
/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [{
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [{
            path: '/redirect/:path(.*)',
            component: (resolve) => require(['@/views/redirect'], resolve)
        }, ]
    },
    {
        path: '/protocol',
        meta: {
            title: "用户协议",
            show: true,
        },
        component: (resolve) => require(['@/views/protocol'], resolve),
        hidden: true
    },
    {
        path: '/fenTanProtocol',
        meta: {
            title: "封坛协议",
            show: true,
        },
        component: (resolve) => require(['@/views/fenTanProtocol'], resolve),
        hidden: true
    },
    {
        path: '/login',
        component: (resolve) => require(['@/views/login'], resolve),
        hidden: true
    },
    {
        path: '/404',
        component: (resolve) => require(['@/views/error/404'], resolve),
        hidden: true
    },
    {
        path: '/500',
        component: (resolve) => require(['@/views/error/500'], resolve),
        hidden: true
    },
    {
        path: '/401',
        component: (resolve) => require(['@/views/error/401'], resolve),
        hidden: true
    },

    {
        path: '',
        component: Layout,
        redirect: 'welcome',
        children: [{
            path: 'welcome',
            component: (resolve) => require(['@/views/welcome'], resolve),
            name: 'Welcome',
            meta: {
                title: '控制台',
                icon: 'dashboard',
                noCache: true,
                affix: true
            }
        }, ]
    },

    {
        path: '/deploy',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'addRole',
            component: (resolve) => require(['@/views/deploy/addRole'], resolve),
            name: 'AddRole',
            meta: {
                title: '添加角色权限'
            }
        }, ]
    },
    {
        path: '/wine',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'addWine',
            component: (resolve) => require(['@/views/wine/addWine'], resolve),
            name: 'AddWine',
            meta: {
                title: '添加酒水'
            }
        }, ]
    },
    {
        path: '/wine',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'addWineFinish',
            component: (resolve) => require(['@/views/wine/addWineFinish'], resolve),
            name: 'AddWineFinish',
            meta: {
                title: '添加成品酒水'
            }
        }, ]
    },
    {
        path: '/wine',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'editWine',
            component: (resolve) => require(['@/views/wine/editWine'], resolve),
            name: 'EditWine',
            meta: {
                title: '编辑酒水'
            }
        }, ]
    },
    {
        path: '/fengtan',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'addFengtan',
            component: (resolve) => require(['@/views/fengtan/addFengtan'], resolve),
            name: 'AddFengtan',
            meta: {
                title: '添加封坛'
            }
        }, ]
    },
    {
        path: '/fengtan',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'addDistribution',
            component: (resolve) => require(['@/views/fengtan/addDistribution'], resolve),
            name: 'AddDistribution',
            meta: {
                title: '添加分销封坛'
            }
        }, ]
    },

    {
        path: '/order',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'takeWine',
            component: (resolve) => require(['@/views/order/takeWine'], resolve),
            name: 'TakeWine',
            meta: { title: '添加取酒订单', }
        }, ]
    },
    {
        path: '/order',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'sample',
            component: (resolve) => require(['@/views/order/sample'], resolve),
            name: 'sample',
            meta: { title: '添加取样订单', }
        }, ]
    },
    {
        path: '/fengtan',
        component: Layout,
        redirect: 'noredirect',
        children: [{
            path: 'managementPage',
            component: (resolve) => require(['@/views/fengtan/managementPage'], resolve),
            name: 'managementPage',
            meta: { title: '预约管理', }
        }, ]
    },
    // {
    //     path: '/fengtan',
    //     component: Layout,
    //     hidden: true,
    //     redirect: 'noredirect',
    //     children: [{
    //         path: 'fengtanDetail',
    //         component: (resolve) => require(['@/views/fengtan/fengtanDetail'], resolve),
    //         name: 'FengtanDetail',
    //         meta: { title: '封坛详情', }
    //     }, ]
    // },
    // 新页面
    // {
    //     path: '/fengtan',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //             path: 'fengtanManagement',
    //             component: (resolve) => require(['@/views/fengtan/fengtanManagement'], resolve),
    //             name: 'FengtanManagement',
    //             meta: { title: '封坛管理', }
    //         },
    //         {
    //             path: 'fengtanCertificate',
    //             component: (resolve) => require(['@/views/fengtan/fengtanCertificate'], resolve),
    //             name: 'FengtanCertificate',
    //             meta: { title: '实物证书管理', }
    //         },
    //         {
    //             path: 'fengtanDetail',
    //             component: (resolve) => require(['@/views/fengtan/fengtanDetail'], resolve),
    //             name: 'FengtanDetail',
    //             meta: { title: '封坛详情', }
    //         },
    //     ]
    // },
    // {
    //     path: '/order',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //             path: 'orderManagement',
    //             component: (resolve) => require(['@/views/order/orderManagement'], resolve),
    //             name: 'OrderManagement',
    //             meta: { title: '订单管理', }
    //         },
    //         {
    //             path: 'orderDetail',
    //             component: (resolve) => require(['@/views/order/orderDetail'], resolve),
    //             name: 'OrderDetail',
    //             meta: { title: '订单详情' }
    //         }
    //     ]
    // },
    // {
    //     path: '/wine',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //             path: 'wineManagement',
    //             component: (resolve) => require(['@/views/wine/wineManagement'], resolve),
    //             name: 'WineManagement',
    //             meta: { title: '酒水管理', }
    //         },
    //         {
    //             path: 'wineSKU',
    //             component: (resolve) => require(['@/views/wine/wineSKU'], resolve),
    //             name: 'WineSKU',
    //             meta: { title: '酒水SKU管理', }
    //         },
    //         {
    //             path: 'wineSampling',
    //             component: (resolve) => require(['@/views/wine/wineSampling'], resolve),
    //             name: 'WineSampling',
    //             meta: { title: '取样管理', }
    //         },
    //         {
    //             path: 'addWine',
    //             component: (resolve) => require(['@/views/wine/addWine'], resolve),
    //             name: 'AddWine',
    //             meta: { title: '添加酒水', }
    //         },
    //     ]
    // },
    // {
    //     path: '/operate',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //         path: 'operateIndex',
    //         component: (resolve) => require(['@/views/operate/operateIndex'], resolve),
    //         name: 'OperateIndex',
    //         meta: { title: '首页运营', }
    //     }]
    // },
    // {
    //     path: '/user',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //         path: 'userManagement',
    //         component: (resolve) => require(['@/views/user/userManagement'], resolve),
    //         name: 'UserManagement',
    //         meta: { title: '用户管理', }
    //     }]
    // },
    // {
    //     path: '/financialAffairs',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //             path: 'invoiceManagement',
    //             component: (resolve) => require(['@/views/financialAffairs/invoiceManagement'], resolve),
    //             name: 'InvoiceManagement',
    //             meta: { title: '发票管理', }
    //         },
    //         {
    //             path: 'invoiceDetail',
    //             component: (resolve) => require(['@/views/financialAffairs/invoiceDetail'], resolve),
    //             name: 'InvoiceDetail',
    //             meta: { title: '发票详情', }
    //         },
    //     ]
    // },
    // {
    //     path: '/deploy',
    //     component: Layout,
    //     redirect: 'noredirect',
    //     children: [{
    //             path: 'competence',
    //             component: (resolve) => require(['@/views/deploy/competence'], resolve),
    //             name: 'Competence',
    //             meta: { title: '权限配置', }
    //         },
    //         {
    //             path: 'depot',
    //             component: (resolve) => require(['@/views/deploy/depot'], resolve),
    //             name: 'Depot',
    //             meta: { title: '仓库管理', }
    //         },
    //         {
    //             path: 'level',
    //             component: (resolve) => require(['@/views/deploy/level'], resolve),
    //             name: 'Level',
    //             meta: { title: '等级管理', }
    //         },
    //         {
    //             path: 'addRole',
    //             component: (resolve) => require(['@/views/deploy/addRole'], resolve),
    //             name: 'AddRole',
    //             meta: { title: '添加角色权限', }
    //         },
    //     ]
    // },
]
export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({
        y: 0
    }),
    routes: constantRoutes
})